<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head :title="item.fullname" back="Conventions.Browse">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised">

		<app-content-box>

			<app-input-text label="Name" placeholder="Enter name" v-model="model.name" :textPrefix="item.organisation.name" :validation="$v.model.name" />
			<app-input-date label="Starts on" placeholder="Enter date" v-model="model.date.start" :validation="$v.model.date.start" />
			<app-input-toggle label="Duration" :fullWidth="true" v-model="duration" :options="durationOptions" />

		</app-content-box>

		<app-content-box :tabs="tabs">

			<template v-slot:library>

				<app-input-toggle label="Type" v-model="model.permissions.library.rented" :options="libraryRentedOptions" />
				<app-input-select label="Collection" v-model="model.permissions.library.collection" placeholder="Entire library" :options="references.collections" v-if="model.permissions.library.rented" />

			</template>

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			references: {
				collections: []
			},
			tabs: [
				{ name: 'library', text: 'Library' }
			],
			libraryRentedOptions: {
				0: 'Own',
				1: 'DTW'
			},
			durationOptions: {
				1: '1 day',
				2: '2 days',
				3: '3 days',
				4: '4 days',
				5: '5 days',
				6: '6 days',
				7: '7 days'
			},
			model: {
				id: false,
				name: '',
				date: {
					start: 0,
					end: 0
				}
			},
			duration: 0
		}

	},

	validations: {
		model: {
			date: {}
		}
	},

	watch: {

		duration: function() {

			this.model.date.end = this.model.date.start + (this.duration * 86400)

		}

	},

	methods: {

		onModelUpdate: function() {

			this.duration = Math.ceil((this.model.date.end - this.model.date.start) / 86400)

		}
		
	}

}

</script>

<style scoped>

</style>